import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styles from './super-tours.module.css';
import Slide from '../slide';
// assets
import uptime from '../assets/uptime.svg';
import backed from '../assets/backed.svg';

const Routes = require('../../routes').Routes;

function SuperTours() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/super-tours-cover-bg.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "super-tours-cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      title="Data security and reliable backups"
      text="Super Tours reinforced their backup strategies with the help of our DevOps engineers. A secure, reliable development and production environment are vital for any business."
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      imageClassName={styles.image}
      bgColor="#91121e"
      icons={[{ path: backed }, { path: uptime }]}
    />
  );
}

export default SuperTours;
