import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styles from './skilent.module.css';
import Slide from '../slide';

const Routes = require('../../routes').Routes;

interface Props {
  img: {
    src: string;
    srcSet: string;
  };
}

function Skilent() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_skilent.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "bg_img_skilent.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      title="Skilent — next-generation Human Resources platform"
      text="Brocoders developed a fully-featured platform to help hiring managers and recruiters optimize their workflow."
      caseLink={Routes.CASE_STUDIES_SKILENT}
      containerClassName={styles.skilent}
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      bgColor="#081331"
      imageClassName={styles.image}
      btnClassName={styles.btn}
    />
  );
}

export default Skilent;
