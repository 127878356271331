import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styles from './hoa.module.css';
import Slide from '../slide/customized-slide';

const Routes = require('../../routes').Routes;

function MyRedDog() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_hoa.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "reddog/reddog.png" }) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      title="Updating a Legacy Property Management System to Deliver New Experiences for Owners"
      text="C.I.A. Services, based in the USA, manages 50,000 properties for 150 clients. During
            their transition to digital operations, they faced a challenge: property owners required
            employee assistance to access information, adding to their workload. To tackle this,
            C.I.A. Services collaborated with Brocoders to develop an app, allowing homeowners and
            residents to communicate directly with their property owner's association."
      caseLink={Routes.CASE_STUDIES_HOA_MANAGMENT}
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      bgColor="#23366b"
      containerHeight="800px"
      mobileContainerHeight="1100px"
      btnClassName={styles.button}
      imageClassName={styles.image}
    />
  );
}

export default MyRedDog;
