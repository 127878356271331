import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styles from './anuel.module.css';
import Slide from '../slide';

const Routes = require('../../routes').Routes;

interface Props {
  img: {
    src: string;
    srcSet: string;
  };
}

function Anuel() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_anuel_energy.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "bg_img_anuel_energy.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;
  return (
    <Slide
      title="Fine platform ─ delivering digital transformation and business processes optimization"
      text='"They’re very capable of performing and meeting my needs."'
      author={
        <React.Fragment>
          <span>
            <b>Emmanuel Bukenya</b>,
          </span>{' '}
          <span>CEO,</span> <span>Renewable Energy Startup</span>
        </React.Fragment>
      }
      caseLink={Routes.CASE_STUDIES_ANUEL}
      containerClassName={styles.anuel}
      backgroundImageData={backgroundImageData}
      bgColor="#4263ce"
      imageClassName={styles.image}
      imageData={imageData}
    />
  );
}

export default Anuel;
