import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styles from './99math.module.css';
import Slide from '../slide';

function Math() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_99math.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "img/99math.png" }) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      title={`99math \n\n Supporting the Development of an App for Practicing Math Skills`}
      text={
        <React.Fragment>
          <span className={styles.paragraph}>
            99Math needed help supporting their app that helps students practice math skills via
            live and practice games. We started the project with one engineer and scaled the team to
            6 experts.
          </span>
          <ul className={styles.list}>
            <li className={styles.item} key="DevOps engineer">
              Provided a DevOps engineer to streamline software delivery
            </li>
            <li className={styles.item} key="front-end developer">
              Added a front-end developer to help transform the app
            </li>
            <li className={styles.item} key="the team to 6 engineers">
              Grew the team to 6 engineers including a DevOps specialist, front-end and backend
              developers
            </li>
          </ul>
        </React.Fragment>
      }
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      imageClassName={styles.image}
      bgColor="#572497"
      linkText="Client feedback"
      caseLink="https://clutch.co/profile/brocoders?page=2#review-2039193"
      target="_blank"
      btnClassName={styles.caseLink}
      containerClassName={styles.container}
      contentClassName={styles.content}
    />
  );
}

export default Math;
