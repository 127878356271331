import * as React from 'react';
import styles from './korobox.module.css';
import Slide from '../slide';
import { graphql, useStaticQuery } from 'gatsby';

const Routes = require('../../routes').Routes;

interface Props {
  img: {
    src: string;
    srcSet: string;
  };
}

function KoroBox() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_korobox.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "bg_img_korobox.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      title="Digitalized snacks service for office"
      text='"Koroboks is our all-time favourite internal project that makes an office life a little bit comfier and sweeter. Initially, I was the project proponent, but in fact, the team that carried out this project has made it a fully-functioning app by turning a simple idea into what it is now"'
      author={
        <React.Fragment>
          <span>
            <b>Rodion Salnik</b>,
          </span>{' '}
          <span>CEO & Co-Founder at Brocoders</span>
        </React.Fragment>
      }
      caseLink={Routes.CASE_STUDIES_KOROBOX}
      containerClassName={styles.korobox}
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      bgColor="#ef6d2e"
      imageClassName={styles.image}
    />
  );
}

export default KoroBox;
