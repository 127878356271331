import React from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';
import { Item } from '../Types';

import styles from './slide.module.css';
import BackgroundImage from 'gatsby-background-image';

function SlideItem({
  title,
  text,
  author,
  caseLink,
  containerClassName,
  contentClassName,
  backgroundImageData,
  imageData,
  bgColor,
  imageClassName,
  btnClassName,
  icons,
  linkText = 'Case Study',
  target = '_self',
}: Item) {
  return (
    <div className={clsx(styles.container, containerClassName, 'lazy')}>
      <div className={styles.bgAfter} style={{ backgroundColor: bgColor }} />
      <BackgroundImage className={styles.bg} fluid={backgroundImageData} />
      <BackgroundImage className={imageClassName} fluid={imageData} />
      <div className={clsx(styles.content, contentClassName)}>
        <h2 className={styles.title}>{title}</h2>
        <blockquote className={styles.quotes}>
          <p className={styles.text}>{text}</p>
          {icons && (
            <div className={styles.iconsContainer}>
              {icons.map((icon, i) => {
                if (icon.render) {
                  return <React.Fragment key={i}>{icon.render()}</React.Fragment>;
                }
                return <img src={icon.path} key={i} alt={`icon # ${i}`} />;
              })}
            </div>
          )}
          {author && <p className={styles.author}>{author}</p>}
        </blockquote>
        {caseLink && (
          <Link
            to={caseLink}
            className={clsx('btn btn_48 btn_chevron btn__black', styles.btn, btnClassName)}
            target={target}
          >
            {linkText}
          </Link>
        )}
      </div>
    </div>
  );
}

export default SlideItem;
