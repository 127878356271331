import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styles from './converHero.module.css';
import Slide from '../slide';

const Routes = require('../../routes').Routes;

function ConverHero() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_converthero.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "img/converthero-bg.png" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      title="Developing an exit-intent popup software for ConvertHero"
      text="We helped ConvertHero create a drag-and-drop popup builder with customizable popup templates and a reporting dashboard."
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      bgColor="#3A7800"
      imageClassName={styles.image}
      caseLink={Routes.CASE_STUDIES_CONVERTHERO}
    />
  );
}

export default ConverHero;
