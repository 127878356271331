import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styles from './area-butler.module.css';
import Slide from '../slide/customized-slide';

const Routes = require('../../routes').Routes;

function AreaButler() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_area_butler.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "area-butler.png" }) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      title="Joining the Team at AreaButler to Build a Geolocation Solution That Enhances Property Sales for Real Estate Agents"
      text="AreaButler is a German proptech startup that offers location analysis tools that examine and describe the environment of properties to appeal to potential buyers and renters. They needed a skilled full-stack developer to drive the development of their product. Working closely with the client in an iterative and Agile way, Brocoders blended into their team effortlessly."
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      caseLink={Routes.CASE_STUDIES_AREABUTLER}
      bgColor="#b80f4c"
      mobileContainerHeight="1100px"
      containerHeight="800px"
      titleMaxWidth="470px"
      imageClassName={styles.image}
      contentClassName={styles.content}
      btnClassName={styles.button}
    />
  );
}

export default AreaButler;
