import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styles from './heypractice.module.css';
import Slide from '../slide';

const Routes = require('../../routes').Routes;

function Heypractice() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_heypractice.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "img/heypractice-bg.png" }) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      title="AI-based Interactive Learning Platform for Sales and Marketing"
      text="How Brocoders helped to build an interactive digital learning platform and integrate AI-based units to create an exclusive training tool for one of the fast-growing EdTechs in Europe."
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      imageClassName={styles.image}
      bgColor="#23398D"
      caseLink={Routes.CASE_STUDIES_HEYPRACTICE}
    />
  );
}

export default Heypractice;
