import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styles from './heypractice2.module.css';
import Slide from '../slide';

const Routes = require('../../routes').Routes;

function Heypractice() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_heypractice.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "img/heypractice-bg.png" }) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      title={`HeyPractice \n\n Rebuilding an AI-Powered Digital Learning Platform for Onboarding Sales Employees`}
      text={
        <React.Fragment>
          <span className={styles.paragraph}>
            Brocoders helped one of the fastest-growing edtech companies in Europe revitalize their
            interactive learning platform.
          </span>
          <ul className={styles.list}>
            <li className={styles.item} key="AI solutions">
              Integrated AI solutions based on IBM Watson and PRECIRE AI
            </li>
            <li className={styles.item} key="SCORM">
              Made the course content compatible with SCORM standards
            </li>
            <li className={styles.item} key="AWS">
              Moved the infrastructure to AWS
            </li>
            <li className={styles.item} key="CI/CD pipelines">
              Set up CI/CD pipelines
            </li>
            <li className={styles.item} key="applications">
              Rebuilt frontend and backend applications
            </li>
          </ul>
        </React.Fragment>
      }
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      imageClassName={styles.image}
      bgColor="#23398D"
      caseLink={Routes.CASE_STUDIES_HEYPRACTICE}
      btnClassName={styles.caseLink}
      containerClassName={styles.container}
      contentClassName={styles.content}
    />
  );
}

export default Heypractice;
