import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styles from './revenue-boosters.module.css';
import Slide from '../slide';

const Routes = require('../../routes').Routes;

function RevenueBoosters() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_revenue.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "revenue-boost/revenue_boosters_cover.png" }) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      title="Route Management Software for Amusement Operator"
      text="How Brocoders built an MVP of a route management SaaS app in 3,5 Months replacing their existing software that ceased functioning."
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      imageClassName={styles.image}
      bgColor="#00592E"
      caseLink={Routes.CASE_STUDIES_REVENUE_BOOSTERS}
    />
  );
}

export default RevenueBoosters;
