import React from 'react';

const ReactLogo = ({ color = '#afb2b8' }) => (
  <svg fill="none" height="50" viewBox="0 0 140 50" width="140" xmlns="http://www.w3.org/2000/svg">
    <g fill={color}>
      <path d="m21.3 28.3996c2.1 0 3.8-1.7 3.8-3.9s-1.7-3.9-3.8-3.9-3.8 1.7-3.8 3.9 1.7 3.9 3.8 3.9z" />
      <path d="m21.3 33.1998c-5.4 0-10.1-.6-13.6-1.9-4.7-1.6-7.7-4.3-7.7-6.9 0-2.4 2.8-5 7.3-6.5 3.8-1.3 8.8-2.1 14-2.1 5.1 0 10 .7 13.8 2.1 4.6 1.6 7.5 4.1 7.5 6.6 0 2.6-3.1 5.2-8 6.9-3.6 1.1-8.3 1.8-13.3 1.8zm0-16c-5 0-9.9.7-13.5 2-3.8 1.4-6.4 3.4-6.4 5.2 0 1.5 2.1 3.9 6.8 5.5 3.4 1.2 7.9 1.8 13.1 1.8 4.9 0 9.5-.6 12.8-1.8 4.9-1.6 7-4 7-5.5 0-1.8-2.6-3.9-6.6-5.2-3.5-1.3-8.3-2-13.2-2z" />
      <path d="m12.5007 43.6995c-.7 0-1.4-.2-1.9-.5-2.10004-1.2-2.80004-4.9-1.90004-9.7.8-4 2.60004-8.8 5.20004-13.3 2.5-4.5 5.6-8.4 8.6-11.09997 3.7-3.3 7.3-4.5 9.4-3.3 2.2 1.3 2.9 5.29997 1.9 10.49997-.8 3.7-2.6 8.2-5.1 12.6-2.7 4.7-5.6 8.5-8.4 11-2.8 2.4-5.6 3.8-7.8 3.8zm2.6-22.8c-2.5 4.4-4.3 9.1-5 12.9-.90004 4.7 0 7.5 1.3 8.2 1.3.8 4.4.1 8.1-3.2 2.7-2.4 5.5-6.1 8.1-10.6 2.4-4.3 4.2-8.6 4.9-12.2 1-5.1.1-8.19997-1.2-8.99997-1.2-.7-4.1 0-7.7 3.09997-3.1 2.6-6 6.4-8.5 10.8z" />
      <path d="m30.0002 43.7004c-2 0-4.7-1.3-7.4-3.6-3.1-2.7-6.2-6.7-8.8-11.2s-4.39996-9.2-5.19996-13.1c-.8-5-.1-8.79996 1.99996-9.99996 2.2-1.3 6 .1 9.9 3.6 2.8 2.49996 5.7 6.29996 8.2 10.79996 2.7 4.7 4.5 9.2 5.2 12.9 1 4.9.2 8.9-2 10.2-.5.2-1.2.4-1.9.4zm-14.9-15.6c2.5 4.4 5.6 8.3 8.5 10.9 3.5 3.1 6.4 3.7 7.6 3 1.3-.8 2.3-3.8 1.3-8.7-.7-3.5-2.4-7.8-5.1-12.4-2.5-4.3-5.3-8-8-10.4-3.7-3.49996-6.8-4.19996-8.1-3.49996s-2.09996 3.59996-1.2 8.39996c.8 3.9 2.5 8.4 5 12.7z" />
      <path d="m60.0424 34.6833-3.5488-7.2041h-4.2787v7.2041h-2.2149v-18.3666h5.9398c2.0974 0 3.6579.4662 4.6814 1.3986 1.0236.9148 1.5353 2.2606 1.5353 4.0375 0 1.3194-.2768 2.4366-.8305 3.3514-.5538.9148-1.3675 1.5657-2.4414 1.9527l3.8256 7.6264zm-4.0774-9.3944c1.3088 0 2.2652-.2639 2.8692-.7917.6209-.5453.9313-1.4602.9313-2.7444 0-1.1787-.3104-2.0056-.9313-2.4806-.604-.4926-1.5688-.7389-2.8944-.7389h-3.7249v6.7556z" />
      <path d="m77.1804 28.5611h-9.3125c.1342 1.3546.5621 2.4366 1.2836 3.2458.7383.7917 1.7367 1.1875 2.9951 1.1875.7551 0 1.485-.1583 2.1897-.475.7215-.3342 1.4514-.818 2.1897-1.4513h.1006v2.3222c-.7382.5629-1.4681.9764-2.1896 1.2403-.7216.2463-1.4934.3694-2.3156.3694-1.3423 0-2.5085-.3079-3.4984-.9236-.99-.6158-1.7535-1.469-2.2904-2.5597-.5202-1.1084-.7802-2.375-.7802-3.8 0-1.3547.2349-2.5686.7047-3.6417.4866-1.0907 1.1745-1.944 2.0638-2.5597.8893-.6158 1.9296-.9236 3.121-.9236 1.2081 0 2.24.3166 3.0957.95.8725.6157 1.5269 1.4689 1.9632 2.5597.453 1.0907.6796 2.3222.6796 3.6944zm-5.7134-6.043c-1.0403 0-1.8708.3958-2.4917 1.1875-.6208.7916-.9899 1.812-1.1074 3.0611h7.0221c-.1175-1.2843-.4614-2.3135-1.0319-3.0875-.5537-.7741-1.3508-1.1611-2.3911-1.1611z" />
      <path d="m88.8923 34.6833-.3523-1.9791c-.6544.7564-1.3675 1.3282-2.1394 1.7152-.7551.3871-1.6108.5806-2.5672.5806-1.1913 0-2.1561-.3519-2.8944-1.0556-.7383-.7213-1.1074-1.6713-1.1074-2.85 0-1.5305.5872-2.6564 1.7618-3.3777 1.1745-.7213 2.6846-1.082 4.5303-1.082.6712 0 1.4095.044 2.2149.132v-.95c0-.95-.2517-1.7065-.7551-2.2695-.4866-.5805-1.1242-.8708-1.9128-.8708-.9396 0-1.7954.1319-2.5672.3958-.7551.2639-1.5353.6773-2.3407 1.2403v-2.2431c.7886-.5453 1.594-.9236 2.4162-1.1347.8222-.2287 1.7618-.343 2.8189-.343 1.3423 0 2.433.4486 3.2719 1.3458.8558.8972 1.2837 2.0847 1.2837 3.5625v6.1222l.5537 3.0611zm-4.6059-1.6361c.8054 0 1.5269-.1759 2.1645-.5278.6377-.3518 1.2669-.862 1.8877-1.5305v-2.5861h-2.3155c-2.5337 0-3.8005.8532-3.8005 2.5597 0 .6333.1929 1.1435.5789 1.5306.3859.3694.8809.5541 1.4849.5541z" />
      <path d="m99.6597 35c-1.2249 0-2.2988-.3079-3.2216-.9236-.9229-.6158-1.6276-1.4602-2.1142-2.5333-.4866-1.0908-.7299-2.3223-.7299-3.6945 0-1.3898.2433-2.6301.7299-3.7208.4866-1.1084 1.1913-1.9704 2.1142-2.5861.9228-.6334 2.0051-.95 3.2467-.95.8722 0 1.6362.0967 2.2902.2902.655.1936 1.275.5454 1.863 1.0556v2.2431h-.101c-.604-.5278-1.233-.9061-1.888-1.1348-.637-.2287-1.367-.343-2.1893-.343-1.1914 0-2.1142.4838-2.7686 1.4514-.6376.9676-.9564 2.2078-.9564 3.7208 0 1.4602.3188 2.6653.9564 3.6153.6544.9324 1.5689 1.3986 2.7434 1.3986.8725 0 1.6525-.1408 2.3405-.4222.688-.2991 1.368-.7653 2.039-1.3986h.101v2.243c-.621.6158-1.292 1.0556-2.014 1.3195-.705.2463-1.518.3694-2.4413.3694z" />
      <path d="m110.376 20.9083h3.624v2.0056h-3.624v7.8903c0 .6509.134 1.1787.402 1.5833.286.387.697.5806 1.234.5806.369 0 .696-.0352.981-.1056.302-.088.638-.2199 1.007-.3958v2.0583c-.386.1935-.755.3167-1.107.3694-.353.0704-.781.1056-1.284.1056-1.074 0-1.921-.3606-2.542-1.0819-.604-.7213-.906-1.6801-.906-2.8764v-8.1278h-2.517v-2.0056h2.517v-3.6944l2.215-1.2139z" />
    </g>
  </svg>
);

export default ReactLogo;
