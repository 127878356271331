import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styles from './beyond-green.module.css';
import Slide from '../slide';

const Routes = require('../../routes').Routes;

function BeyondGreen() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_beyond_green.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "bg_img_beyond_green.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      title="Beyond Green — feature-rich meal order management service"
      text='"Brocoders’ leadership team told us to keep the product simple, which made it better. They also never guessed what we needed. All the other agencies that we interviewed took big chances and told us what they thought we needed. They’re a team of authentic listeners."'
      author={
        <>
          <span>
            <b>Greg Christian</b>,{' '}
          </span>
          <span>CEO,</span> <span>Beyond Green Sustainable Food Partners</span>
        </>
      }
      caseLink={Routes.CASE_STUDIES_BEYOND_GREEN}
      contentClassName={styles.content}
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      bgColor="#7cbe70"
      imageClassName={styles.image}
    />
  );
}

export default BeyondGreen;
