import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Slide from '../slide';
import styles from './xellardoor.module.css';

const Routes = require('../../routes').Routes;

function Xellardoor() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_xellardoor.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "bg_img_xellardoor.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      title="Bringing fashion enthusiasts and fashion lovers together"
      text="Take a sneak peak how Brocoders developed feature-rich online fashion marketplace Xellardoor with outstanding features for fashion enthusiasts."
      caseLink={Routes.CASE_STUDIES_XELLARDOOR}
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      bgColor="#190586"
      imageClassName={styles.image}
      btnClassName={styles.btn}
    />
  );
}

export default Xellardoor;
