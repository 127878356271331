import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styles from './ifit.module.css';
import Slide from '../slide';
// assets
import garmin from './assets/garmin.svg';
import googleFit from './assets/google-fit.svg';
import halo from './assets/halo.svg';
import ouraring from './assets/ouraring.svg';
import strava from './assets/strava.svg';

const Routes = require('../../routes').Routes;

function Ifit() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg-ifit.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "bg-img-i-fit.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      containerClassName={styles.mycurrency}
      title="Fitness APII&#10;integrations for Fitness and Workout App"
      text="Learn how Brocoders integrated a customer software with GoogleFit, Strava, Garmin, OuraRing and Amazon Hallo"
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      bgColor="#253356"
      imageClassName={styles.image}
      icons={[
        { path: googleFit },
        { path: strava },
        { path: garmin },
        { path: ouraring },
        { path: halo },
      ]}
      caseLink={Routes.CASE_STUDIES_I_FIT}
    />
  );
}

export default Ifit;
