import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { Link } from 'gatsby';
import clsx from 'clsx';
//styles
import styles from './customized-slide.module.css';
//types
import { ICustomizedItem } from '../../Types';

function CustomizedSlide({
  title,
  text,
  author,
  caseLink,
  backgroundImageData,
  imageData,
  icons,
  caseName,
  linkText = 'Case Study',
  target = '_self',
  bgColor,
  advantageList,
  containerClassName,
  contentClassName,
  imageClassName,
  btnClassName,
  containerHeight = '687px',
  mobileContainerHeight = '900px',
  contentMaxWidth = '597px',
  titleMaxWidth = '450px',
}: ICustomizedItem) {
  return (
    <div
      className={clsx(styles.container, containerClassName, 'lazy')}
      style={
        {
          '--containerHeight': containerHeight,
          '--mobileContainerHeight': mobileContainerHeight,
        } as React.CSSProperties
      }
    >
      <div className={styles.bgAfter} style={{ backgroundColor: bgColor }} />
      <BackgroundImage className={styles.bg} fluid={backgroundImageData} />
      <BackgroundImage className={imageClassName} fluid={imageData} />
      <div
        className={clsx(contentClassName, styles.content)}
        style={
          {
            '--contentMaxWidth': contentMaxWidth,
          } as React.CSSProperties
        }
      >
        {!!caseName && <h2 className={styles.caseName}>{caseName}</h2>}
        <p
          className={styles.title}
          style={
            {
              '--titleMaxWidth': titleMaxWidth,
            } as React.CSSProperties
          }
        >
          {title}
        </p>
        <blockquote className={styles.quotes}>
          <p className={styles.text}>{text}</p>
          {!!advantageList && (
            <ul className={styles.list}>
              {advantageList.map((item) => (
                <li className={styles.listItem} key={item}>
                  {item}
                </li>
              ))}
            </ul>
          )}
          {icons && (
            <div className={styles.iconsContainer}>
              {icons.map((icon, i) => {
                if (icon.render) {
                  return <React.Fragment key={i}>{icon.render()}</React.Fragment>;
                }
                return <img src={icon.path} key={i} alt={`icon # ${i}`} />;
              })}
            </div>
          )}
          {author && <p className={styles.author}>{author}</p>}
        </blockquote>
        {caseLink && (
          <Link
            to={caseLink}
            className={clsx('btn btn_48 btn_chevron btn__black', btnClassName, styles.btn)}
            target={target}
          >
            {linkText}
          </Link>
        )}
      </div>
    </div>
  );
}

export default CustomizedSlide;
