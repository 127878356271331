import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styles from './everypig2.module.css';
import Slide from '../slide';

const Routes = require('../../routes').Routes;

function EveryPig2() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_everypig.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "bg_img-ep.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      containerClassName={styles.everypig}
      title="Predicting pig mortality to make informed treatment decisions"
      text="Brocoders joined forces with EveryPig, a platform for swine production management, to develop an AI algorithm that predicts pig mortality and offers automated treatment suggestions using image-based diagnosis."
      caseLink={Routes.CASE_STUDIES_EVERYPIG}
      bgColor="#cf2e01"
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      imageClassName={styles.image}
    />
  );
}

export default EveryPig2;
