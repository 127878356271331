import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styles from './adact.module.css';
import Slide from '../slide';

const Routes = require('../../routes').Routes;

function Adact() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_adact.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "img/adact-bg.png" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      title="Building a marketing&#10;gamification platform for Adact"
      text="Brocoders helped Adact develop a platform that allows marketing professionals to build and manage branded gamification campaigns with no code."
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      imageClassName={styles.image}
      bgColor="#f99b12"
      caseLink={Routes.CASE_STUDIES_ADACT}
    />
  );
}

export default Adact;
