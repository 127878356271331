import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styles from './amped.module.css';
import Slide from '../slide';
// assets
import secure from '../assets/secure.svg';
import reduction from '../assets/reduction180.svg';

const Routes = require('../../routes').Routes;

function Amped() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/amped_bg.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "amped-cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      title="Cutting AWS costs"
      text="Our DevOps engineers created a cost-effective, cross-platform solution that helped our client save a significant chunk of change on AWS and create a secure development environment."
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      imageClassName={styles.image}
      bgColor="#003d5d"
      icons={[{ path: reduction }, { path: secure }]}
    />
  );
}

export default Amped;
