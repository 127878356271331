import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styles from './traders-alloy.module.css';
import Slide from '../slide';
// assets
import moreFeaturesReleased from '../assets/more-features-released.svg';
import fasterDeployment from '../assets/faster-deployment.svg';

const Routes = require('../../routes').Routes;
interface ISlide {
  slideTitle?: string;
}

function TradersAlloy({ slideTitle = 'Traders Alloy' }: ISlide) {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/traders-alloy-bg.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "traders-alloy-cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      title={slideTitle}
      text="Today, Traders Alloy is fully autonomous, with an occasional helping hand from BroDevOps engineer. We can deploy several times a day without skipping a beat and smash any bugs we encounter with autotests."
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      imageClassName={styles.image}
      bgColor="#315c83"
      icons={[{ path: fasterDeployment }, { path: moreFeaturesReleased }]}
      caseLink={Routes.CASE_STUDIES_TRADERS_ALLOY}
    />
  );
}

export default TradersAlloy;
