import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styles from './condogenie.module.css';
import Slide from '../slide';

const Routes = require('../../routes').Routes;

function Condogenie() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_condogenie.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "bg_img_condo_genie.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      title="CondoGenie — multi-functioning platform for condominium management"
      text="A sneak peek at how Brocoders developed a feature-rich product to help condo residents, condo board directors and property managers effectively cope with numerous condominium-related issues."
      caseLink={Routes.CASE_STUDIES_CONDOGENIE}
      contentClassName={styles.content}
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      bgColor="#66820a"
      imageClassName={styles.image}
    />
  );
}

export default Condogenie;
