import React from 'react';

const ReactNativeLogo = ({ color = '#afb2b8' }) => (
  <svg fill="none" height="50" viewBox="0 0 140 50" width="140" xmlns="http://www.w3.org/2000/svg">
    <g fill={color}>
      <path d="m38.4879 32.486-2.6461-5.25h-3.1903v5.25h-1.6515v-13.3846h4.4289c1.5639 0 2.7275.3398 3.4906 1.0192.7632.6667 1.1448 1.6475 1.1448 2.9424 0 .9615-.2064 1.7756-.6193 2.4423-.4129.6666-1.0197 1.141-1.8204 1.423l2.8526 5.5577zm-3.0402-6.8461c.9759 0 1.689-.1923 2.1394-.5769.4629-.3975.6944-1.0642.6944-2 0-.859-.2315-1.4616-.6944-1.8077-.4504-.359-1.1698-.5385-2.1582-.5385h-2.7774v4.9231z" />
      <path d="m51.2665 28.0245h-6.9436c.1001.9872.4191 1.7756.9571 2.3654.5505.5769 1.2949.8654 2.2332.8654.563 0 1.1072-.1154 1.6327-.3462.538-.2436 1.0822-.5961 1.6327-1.0577h.0751v1.6923c-.5505.4103-1.0947.7116-1.6327.9039-.538.1795-1.1135.2692-1.7266.2692-1.0008 0-1.8704-.2244-2.6085-.6731-.7382-.4487-1.3074-1.0705-1.7078-1.8654-.3878-.8077-.5818-1.7307-.5818-2.7692 0-.9872.1752-1.8718.5255-2.6538.3628-.7949.8758-1.4167 1.5389-1.8654s1.4388-.6731 2.327-.6731c.9008 0 1.6703.2308 2.3083.6923.6506.4487 1.1386 1.0705 1.4638 1.8654.3378.7949.5067 1.6923.5067 2.6923zm-4.26-4.4039c-.7757 0-1.395.2885-1.8579.8654-.4629.577-.7381 1.3205-.8257 2.2308h5.2359c-.0876-.9359-.3441-1.6859-.7695-2.25-.4128-.5641-1.0071-.8462-1.7828-.8462z" />
      <path d="m59.9994 32.486-.2628-1.4423c-.4879.5513-1.0196.968-1.5951 1.25-.563.2821-1.2011.4231-1.9142.4231-.8883 0-1.6077-.2564-2.1582-.7692-.5505-.5257-.8257-1.218-.8257-2.077 0-1.1153.4378-1.9359 1.3136-2.4615s2.0018-.7885 3.378-.7885c.5005 0 1.051.0321 1.6515.0962v-.6923c0-.6923-.1877-1.2436-.563-1.6539-.3628-.423-.8383-.6346-1.4263-.6346-.7006 0-1.3387.0962-1.9142.2885-.563.1923-1.1447.4936-1.7453.9038v-1.6346c.588-.3974 1.1886-.6731 1.8016-.8269.6131-.1667 1.3137-.25 2.1019-.25 1.0009 0 1.8141.3269 2.4397.9808.638.6538.9571 1.5192.9571 2.5961v4.4616l.4128 2.2307zm-3.4343-1.1923c.6005 0 1.1385-.1282 1.6139-.3846s.9446-.6282 1.4075-1.1154v-1.8846h-1.7265c-1.8892 0-2.8338.6218-2.8338 1.8654 0 .4615.1439.8333.4316 1.1154.2878.2692.6569.4038 1.1073.4038z" />
      <path d="m68.0278 32.7168c-.9133 0-1.714-.2244-2.4021-.6731s-1.2136-1.0641-1.5764-1.8461c-.3628-.7949-.5442-1.6923-.5442-2.6923 0-1.0129.1814-1.9167.5442-2.7116.3628-.8077.8883-1.4359 1.5764-1.8846.6881-.4615 1.4951-.6923 2.4209-.6923.6506 0 1.2198.0705 1.7078.2115.4879.1411.9508.3975 1.3887.7693v1.6346h-.0751c-.4504-.3846-.9195-.6603-1.4075-.8269-.4754-.1667-1.0196-.25-1.6327-.25-.8883 0-1.5764.3525-2.0643 1.0577-.4754.7051-.7131 1.6089-.7131 2.7115 0 1.0641.2377 1.9423.7131 2.6346.4879.6795 1.1698 1.0192 2.0456 1.0192.6505 0 1.2323-.1025 1.7453-.3077.5129-.2179 1.0196-.5576 1.5201-1.0192h.075v1.6346c-.4629.4487-.9633.7693-1.5013.9616-.5255.1795-1.1323.2692-1.8204.2692z" />
      <path d="m76.018 22.4476h2.7024v1.4615h-2.7024v5.75c0 .4744.1001.859.3003 1.1539.2127.282.5192.423.9196.423.2752 0 .5192-.0256.7319-.0769.2252-.0641.4754-.1603.7506-.2885v1.5c-.2877.1411-.563.2308-.8257.2693-.2627.0513-.5818.0769-.9571.0769-.8007 0-1.4325-.2628-1.8954-.7885-.4504-.5256-.6756-1.2243-.6756-2.0961v-5.9231h-1.8767v-1.4615h1.8767v-2.6923l1.6514-.8847z" />
      <path d="m96.4384 19.1014v13.3846h-1.6515l-7.0187-10.3269v10.3269h-1.6515v-13.3846h1.6515l7.0187 10.3269v-10.3269z" />
      <path d="m106.245 32.486-.263-1.4423c-.488.5513-1.02.968-1.595 1.25-.563.2821-1.201.4231-1.915.4231-.888 0-1.607-.2564-2.158-.7692-.5502-.5257-.8254-1.218-.8254-2.077 0-1.1153.4379-1.9359 1.3134-2.4615.876-.5256 2.002-.7885 3.378-.7885.501 0 1.051.0321 1.652.0962v-.6923c0-.6923-.188-1.2436-.563-1.6539-.363-.423-.839-.6346-1.427-.6346-.7 0-1.338.0962-1.914.2885-.563.1923-1.145.4936-1.745.9038v-1.6346c.588-.3974 1.189-.6731 1.802-.8269.613-.1667 1.313-.25 2.101-.25 1.001 0 1.815.3269 2.44.9808.638.6538.957 1.5192.957 2.5961v4.4616l.413 2.2307zm-3.435-1.1923c.601 0 1.139-.1282 1.614-.3846.476-.2564.945-.6282 1.408-1.1154v-1.8846h-1.727c-1.889 0-2.834.6218-2.834 1.8654 0 .4615.144.8333.432 1.1154.288.2692.657.4038 1.107.4038z" />
      <path d="m112.64 22.4476h2.703v1.4615h-2.703v5.75c0 .4744.1.859.301 1.1539.212.282.519.423.919.423.275 0 .519-.0256.732-.0769.225-.0641.476-.1603.751-.2885v1.5c-.288.1411-.563.2308-.826.2693-.263.0513-.582.0769-.957.0769-.801 0-1.433-.2628-1.896-.7885-.45-.5256-.675-1.2243-.675-2.0961v-5.9231h-1.877v-1.4615h1.877v-2.6923l1.651-.8847z" />
      <path d="m118.273 20.1783c-.351 0-.638-.1153-.864-.3461-.212-.2308-.319-.5257-.319-.8846 0-.359.107-.6539.319-.8847.226-.2307.513-.3461.864-.3461.35 0 .631.1154.844.3461.225.2308.338.5257.338.8847 0 .3589-.113.6538-.338.8846-.213.2308-.494.3461-.844.3461zm.825 2.2693v10.0384h-1.651v-10.0384z" />
      <path d="m130.396 22.4476-3.622 10.0384h-2.252l-3.622-10.0384h1.689l3.096 8.673 3.097-8.673z" />
      <path d="m140 28.0245h-6.944c.1.9872.419 1.7756.957 2.3654.551.5769 1.295.8654 2.234.8654.563 0 1.107-.1154 1.632-.3462.538-.2436 1.083-.5961 1.633-1.0577h.075v1.6923c-.55.4103-1.095.7116-1.633.9039-.538.1795-1.113.2692-1.726.2692-1.001 0-1.871-.2244-2.609-.6731s-1.307-1.0705-1.707-1.8654c-.388-.8077-.582-1.7307-.582-2.7692 0-.9872.175-1.8718.525-2.6538.363-.7949.876-1.4167 1.539-1.8654s1.439-.6731 2.327-.6731c.901 0 1.67.2308 2.308.6923.651.4487 1.139 1.0705 1.464 1.8654.338.7949.507 1.6923.507 2.6923zm-4.26-4.4039c-.776 0-1.395.2885-1.858.8654-.463.577-.738 1.3205-.826 2.2308h5.236c-.087-.9359-.344-1.6859-.769-2.25-.413-.5641-1.007-.8462-1.783-.8462z" />
      <path d="m11.1616 27.0364c1.0802 0 1.9546-.8744 1.9546-2.006s-.8744-2.006-1.9546-2.006-1.95457.8744-1.95457 2.006.87437 2.006 1.95457 2.006z" />
      <path d="m11.1616 29.6595c-2.77755 0-5.19504-.36-7.04673-.9773-2.52036-.8744-4.11487-2.3146-4.11487-3.7034 0-1.3373 1.44021-2.6746 3.90913-3.549 1.95456-.7201 4.57779-1.0802 7.25247-1.0802 2.6232 0 5.195.3601 7.1496 1.0802 2.4689.8744 3.9605 2.2117 3.9605 3.549 0 1.3888-1.6459 2.829-4.2177 3.7034-1.8517.6687-4.2692.9773-6.8924.9773zm0-8.1783c-2.52037 0-5.04073.3601-6.89242 1.0287-2.16031.7716-3.13759 1.8003-3.13759 2.5204s1.13159 1.9031 3.34333 2.6746c1.69739.6173 4.012.9259 6.68668.9259 2.5203 0 4.835-.3086 6.5323-.9259 2.4175-.8229 3.4977-1.9545 3.4977-2.6746 0-.6687-1.0287-1.7488-3.2405-2.5204-1.8517-.6686-4.2692-1.0287-6.7895-1.0287z" />
      <path d="m6.68693 35.1109c-.41149 0-.77154-.1028-1.08016-.2571-1.13159-.6687-1.54307-2.6233-1.08015-5.1951.41149-2.1088 1.33733-4.5263 2.67467-6.8924 1.33733-2.3146 2.93181-4.372 4.47491-5.7608 2.006-1.7488 3.8577-2.4175 5.0407-1.6974 1.1831.7201 1.5945 2.829 1.0287 5.5551-.4114 1.9031-1.3373 4.2692-2.6232 6.5324-1.3888 2.4689-2.8804 4.4234-4.3206 5.7093-1.49164 1.2859-2.98328 2.006-4.11487 2.006zm1.4402-11.8816c-1.2859 2.2117-2.21174 4.6292-2.57179 6.5837-.41149 2.3147 0 3.7034.56579 4.012.61723.3601 2.16031-.0514 3.90917-1.6459 1.3373-1.183 2.7775-3.0862 4.1148-5.4522 1.2345-2.2117 2.1603-4.4235 2.5204-6.2237.5143-2.5204.1029-4.0635-.5144-4.4235-.5658-.3087-2.006.0514-3.7548 1.5945-1.4916 1.3887-2.98327 3.3433-4.26917 5.5551z" />
      <path d="m15.6364 35.1117c-1.0802 0-2.4689-.6686-3.9091-1.9031-1.5945-1.3888-3.18906-3.4462-4.57783-5.8122-1.33733-2.3147-2.26318-4.7321-2.67467-6.841-.51436-2.6232-.10287-4.5778 1.02872-5.2979 1.23446-.7201 3.2919 0 5.34938 1.8517 1.4402 1.2859 2.9832 3.2919 4.2691 5.6065 1.3888 2.4689 2.3146 4.7321 2.7261 6.6867.5144 2.6746.1029 4.7321-1.0801 5.4522-.3601.1543-.7201.2571-1.1316.2571zm-7.50965-8.2811c1.2859 2.2117 2.82895 4.2691 4.32065 5.555 1.7488 1.5431 3.1376 1.8517 3.7033 1.5431.6173-.36 1.0288-1.9545.5658-4.2692-.36-1.8002-1.2344-4.012-2.5718-6.3266-1.2344-2.2117-2.6746-4.0634-4.0634-5.2979-1.90311-1.6974-3.39475-2.1088-4.01198-1.7488-.5658.3601-.97729 1.7488-.51436 4.1149.41148 1.9031 1.33733 4.1663 2.57179 6.4295z" />
    </g>
  </svg>
);

export default ReactNativeLogo;
