import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styles from './bb.module.css';
import Slide from '../slide';

const Routes = require('../../routes').Routes;

function Backbone() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_backbone.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "bg_img_backbone.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      containerClassName={styles.backbone}
      title="Enjoying unimaginable experiences with Backbone International"
      text='"It seemed clear that Backbone could profit from an application that supports a large number of the processes, so everyone could find and save their information in the same place."'
      author={
        <React.Fragment>
          <span>
            <b>Tim de Jonge</b>,
          </span>{' '}
          <span>Project Coordinator,</span> <span>Backbone International</span>
        </React.Fragment>
      }
      caseLink={Routes.CASE_STUDIES_BACKBONE}
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      bgColor="#48671c"
      imageClassName={styles.image}
    />
  );
}

export default Backbone;
