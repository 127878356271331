import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styles from './everypig-logistic.module.css';
import Slide from '../slide';

const Routes = require('../../routes').Routes;

function EveryPigLogistic() {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "bg/bg_everypig.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image: file(relativePath: { eq: "bg_img_everypig_logistic.png" }) {
        childImageSharp {
          fluid(maxWidth: 1530) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      containerClassName={styles.everypig}
      title="Helping Everypig to Address the Health Issues in Swine Industry"
      text="A case study on developing a groundbreaking management software for pig owners, producers and veterinarians."
      caseLink={Routes.CASE_STUDIES_EVERYPIG_LOGISTIC}
      bgColor="#cf2e01"
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      imageClassName={styles.image}
    />
  );
}

export default EveryPigLogistic;
