import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styles from './condogenie2.module.css';
import Slide from '../slide/customized-slide';

const Routes = require('../../routes').Routes;

function MyRedDog() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_condogenie2.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "bg_img_condo_genie.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      title="Enhancing Condominium Management with a Communication Platform That Improves Living Quality"
      text="Brocoders teamed up with a Canadian entrepreneur to create a new version of their software that will meet their customers' needs for the long term. Our team helped develop CondoGenie – a feature-rich tool that enhanced condominium management and communication."
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      caseLink={Routes.CASE_STUDIES_CONDOGENIE}
      bgColor="#719403"
      containerHeight="800px"
      mobileContainerHeight="1100px"
      imageClassName={styles.image}
    />
  );
}

export default MyRedDog;
