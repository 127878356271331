import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styles from './dokka.module.css';
import Slide from '../slide';

const Routes = require('../../routes').Routes;

function Dokka() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_dokka.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "bg_img_dokka.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      contentClassName={styles.content}
      title="Bookkeeping and Document Management Solution"
      text={
        'The AI-based Accounting automation solution that eliminates 80% accounts payable workload:\n \n  • Automated invoice processing and management\n  • Integration with top ERP SAP, Quickbooks\n  • Centralized document management. '
      }
      caseLink={Routes.CASE_STUDIES_DOKKA}
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      bgColor="#3757a6"
      imageClassName={styles.image}
      btnClassName={styles.link}
    />
  );
}

export default Dokka;
