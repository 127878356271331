import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styles from '../area-butler/area-butler.module.css';
import Slide from '../slide/customized-slide';

const Routes = require('../../routes').Routes;

function AreaButler2() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_area_butler.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "area-butler.png" }) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      title="Integrating OpenAI's GPT to support real estate agents"
      text="AreaButler, a SaaS platform for real estate used our expertise to inject AI into their solution. We integrated OpenAI's GPT-3.5 to generate descriptions for the property, its surroundings, and the location helping real estate agents save time and enhance property sales."
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      caseLink={Routes.CASE_STUDIES_AREABUTLER}
      bgColor="#b80f4c"
      imageClassName={styles.image}
      contentClassName={styles.content}
      btnClassName={styles.button}
    />
  );
}

export default AreaButler2;
