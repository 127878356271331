import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styles from './shineup.module.css';
import Slide from '../slide/customized-slide';

const Routes = require('../../routes').Routes;

function Shineup() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_shineup.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "shineup.png" }) {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      title="Digitalizing the Entire Process of Renovating Apartments from Task Allocation to Completion"
      text="Oslo-based proptech company, Shineup, provides a digital platform for apartment renovations. Their collaboration with Brocoders resulted in the development of their web solution, with additional support as a hired CTO, and the successful delivery of AI features."
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      // caseLink={Routes.CASE_STUDIES_}
      bgColor="#803efd"
      containerHeight="800px"
      mobileContainerHeight="1100px"
      imageClassName={styles.image}
      contentClassName={styles.content}
    />
  );
}

export default Shineup;
