import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styles from './wagepoint.module.css';
import Slide from '../slide';

const Routes = require('../../routes').Routes;

function Wagepoint() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_wagepoint.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "wagepoint/cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      title="Accelerating Payroll Software Renovation With a Qualified Remote Team"
      text="How Brocoders helped Wagepoint quickly scale their team with skilled tech engineers and set up efficient operational processes while improving the overall quality of the product and adding new features."
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      imageClassName={styles.image}
      btnClassName={styles.link}
      bgColor="#3b6307"
      // caseLink={Routes.CASE_STUDIES_WAGEPOINT}
    />
  );
}

export default Wagepoint;
