import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styles from './bro.module.css';
import Slide from '../slide';

const Routes = require('../../routes').Routes;

function BroCoders() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_brocoders.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        image: file(relativePath: { eq: "bg_img_brocoders.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      containerClassName={styles.brocoders}
      title="Brocoders.com Redesign"
      text='"Thanks to our Gatsby.js developers we were able to deliver Brocoders new website fast and simultaneously meet the requirements for excellent quality, decent website indexation by search engines and blazing-fast loading of pages."'
      author={
        <React.Fragment>
          <span>
            <b>Yulya Glamazdina</b>,
          </span>{' '}
          <span>Marketing Manager,</span> <span>Brocoders</span>
        </React.Fragment>
      }
      caseLink={Routes.CASE_STUDIES_BROCODERS}
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      bgColor="#a60142"
      imageClassName={styles.image}
    />
  );
}

export default BroCoders;
