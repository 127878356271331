import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
// assets
import NodeLogo from 'images/logo/node.jsx';
import ReactLogo from 'images/logo/react.jsx';
// styles
import styles from './betone.module.css';
import Slide from '../slide';

const Routes = require('../../routes').Routes;

function Betone() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_betone.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "bg-img-betone.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      containerClassName={styles.mycurrency}
      title="Gym management system"
      text="Take a sneak peak how Brocoders helped to build the city’s largest, most technologically advanced fitness club"
      backgroundImageData={backgroundImageData}
      caseLink={Routes.CASE_STUDIES_GYM_CLUB}
      imageData={imageData}
      bgColor="#45268c"
      imageClassName={styles.image}
      icons={[
        { render: () => <NodeLogo color="#fff" /> },
        { render: () => <ReactLogo color="#fff" /> },
      ]}
      contentClassName={styles.content}
    />
  );
}

export default Betone;
