import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styles from './everypig.module.css';
import Slide from '../slide';

const Routes = require('../../routes').Routes;

function EveryPig() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_everypig.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "bg_img_everypig.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      containerClassName={styles.everypig}
      title="Taking control of pork production with EveryPig"
      text='"When we jumped onto the EveryPig platform, we expected to dramatically improve our response time to on-farm health issues. And we have. What we didn’t expect was how much the platform would improve caregiver engagement."'
      caseLink={Routes.CASE_STUDIES_EVERYPIG}
      author={
        <React.Fragment>
          <span>
            <b>Megan Roos</b>,
          </span>{' '}
          <span>Vice president & General counsel,</span> <span>RC Family Farms</span>
        </React.Fragment>
      }
      bgColor="#cf2e01"
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      imageClassName={styles.image}
    />
  );
}

export default EveryPig;
