import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
// styles
import styles from './torwod.module.css';
//components
import Slide from '../slide';
// assets
import ReactLogo from 'images/logo/react.jsx';
import ReactNativeLogo from 'images/logo/react-native.jsx';
import NodeLogo from 'images/logo/node.jsx';

function Torwod() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_torwod.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "bg-img-torwod.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      containerClassName={styles.mycurrency}
      title="Workout application"
      text="Read how Brocoders helped people to join the champions’ path and get an instant access to the top training programs"
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      bgColor="#012b8f"
      imageClassName={styles.image}
      icons={[
        { render: () => <ReactLogo color="#fff" /> },
        { render: () => <NodeLogo color="#fff" /> },
        { render: () => <ReactNativeLogo color="#fff" /> },
      ]}
      contentClassName={styles.content}
    />
  );
}

export default Torwod;
