import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styles from './appetini.module.css';
import Slide from '../slide';

const Routes = require('../../routes').Routes;

function Appetini() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_appetini.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "bg_img_appetini.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      containerClassName={styles.appetini}
      title="Appetini — custom-designed meal delivery solution"
      text='"Take a sneak peek at how we developed a food delivery service platform to cover the offices’ needs in home food delivery services in our city."'
      author={
        <>
          <b>Rodion Salnik,</b> <span>CEO & Co-Founder at Brocoders</span>
        </>
      }
      caseLink={Routes.CASE_STUDIES_APPETINI}
      backgroundImageData={backgroundImageData}
      bgColor="#4e9108"
      imageClassName={styles.image}
      imageData={imageData}
    />
  );
}

export default Appetini;
