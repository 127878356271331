import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styles from './ladder-out.module.css';
import Slide from '../slide';

const Routes = require('../../routes').Routes;

function LadderOut() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_ladderout.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "bg_img_ladderout.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      containerClassName={styles.ladderOut}
      title="LadderOut - developing mental health coaching software"
      text='"Hani Soultan contacted us to help him create startup that will help people overcome their porn addiction as well as fully recover right after. In this case you’ll learn about work process and challenges we met during platform’s development."'
      author={
        <React.Fragment>
          <span>
            <b>Andrey Konoplenko</b>,
          </span>{' '}
          <span>CEO & Co-Founder at Brocoders</span>
        </React.Fragment>
      }
      caseLink={Routes.CASE_STUDIES_LADDEROUT}
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      bgColor="#33a9d0"
      imageClassName={styles.image}
    />
  );
}

export default LadderOut;
