import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styles from './hypeboard.module.css';
import Slide from '../slide';

const Routes = require('../../routes').Routes;

function Hypeboard() {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg/bg_hypeboard.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image: file(relativePath: { eq: "img/hypeboard-bg.png" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image1: file(relativePath: { eq: "img/bg_img_hypeboard.png" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundImageData = data.background.childImageSharp.fluid;
  const imageData = data.image.childImageSharp.fluid;

  return (
    <Slide
      title="Creating an Instagram analytics tool for Hypeboard"
      text="Brocoders helped a US-based company in the influencer marketing space develop an app that monitors Instagram profile rankings in real-time and lets users discover new profiles."
      backgroundImageData={backgroundImageData}
      imageData={imageData}
      imageClassName={styles.image}
      bgColor="#ff536b"
      caseLink={Routes.CASE_STUDIES_HYPEBOARD}
    />
  );
}

export default Hypeboard;
